<template>
  <div class="d-flex justify-center m-auto">
    <div
      class="my-bubble-component py-6 px-4 d-flex justify-center my-2"
      :class="getCurrentRoute == 'UserEmbed' ? 'w-100' : ''"
      :style="`background:${activeBot.user_bg_color}; color: ${activeBot.user_text_color}`"
    >
      <div style="max-width: 768px" class="w-100 d-flex">
        <div class="flex-grow-1 d-flex">
          <div>
            <v-btn
              v-clipboard="() => text"
              @success="infoMessage('Copied!')"
              icon
              small
            >
              <v-icon small :color="activeBot.user_text_color"
                >mdi-clipboard-outline</v-icon
              >
            </v-btn>
          </div>
          <!-- <span style="min-width: 45px; width: 45px" class="mr-1">User:</span> -->
          <span v-html="text" class="w-100 text-right px-1"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/helpers/utils";
export default {
  props: {
    text: String,
    activeBot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    infoMessage(v) {
      Utils.showMessageDefault({
        text: "Copied",
        title: "Success",
        confirmButtonColor: "#4285f3",
        confirmButtonTextColor: "white",
        confirmButtonText: "OKAY",
        showCancelButton: false,
      }).then((res) => {});
    },
  },
};
</script>

<style lang="scss">
.my-bubble-component {
  white-space: pre-wrap;
  width: 500px;
  border-radius: 20px;
  pre {
    background: black;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
  }
}
</style>
