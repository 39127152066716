<template>
  <div class="d-flex justify-center m-auto">
    <div class="pr-2 pt-2">
      <v-img :src="activeBot.icon" v-if="activeBot.icon" max-width="40"></v-img>
      <v-img :src="botDefaultIcon" v-else max-width="40"></v-img>
    </div>
    <div
      class="ai-bubble-component py-6 px-4 d-flex justify-center my-2"
      :class="getCurrentRoute == 'UserEmbed' ? 'w-100' : ''"
      :style="`background:${activeBot.ai_bg_color}; color: ${activeBot.ai_text_color}`"
    >
      <div style="max-width: 768px" class="w-100 d-flex">
        <div class="flex-grow-1 input-container d-flex">
          <!-- <span style="min-width: 45px; width: 45px" class="mr-1">AI: </span> -->
          <span v-html="text"></span>
          <div class="d-flex" v-if="!text">
            <div class="cursor" />
            <span style="animation: cursor-blink 1s infinite" class="ml-1"
              >Loading...</span
            >
          </div>
        </div>
        <div>
          <v-btn
            v-clipboard="() => text"
            @success="infoMessage('Copied!')"
            icon
            small
          >
            <v-icon small :color="activeBot.user_text_color"
              >mdi-clipboard-outline</v-icon
            >
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/helpers/utils";
import botIconDefault from "@/assets/bots/robot-outline.png";

export default {
  props: {
    text: String,
    activeBot: {
      type: Object,
      required: true,
    },
  },
  methods: {
    infoMessage(v) {
      Utils.showMessageDefault({
        text: "Copied",
        title: "Success",
        confirmButtonColor: "#4285f3",
        confirmButtonTextColor: "white",
        confirmButtonText: "OKAY",
        showCancelButton: false,
      }).then((res) => {});
    },
  },

  computed: {
    botDefaultIcon() {
      return botIconDefault;
    },

    getCurrentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.ai-bubble-component {
  white-space: pre-wrap;
  width: 500px;
  border-radius: 20px;
  pre {
    background: black;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
  }
}
.input-container {
  position: relative;
}

.cursor {
  // position: absolute;
  // top: 0;
  // left: 0;
  height: 18px;
  width: 8px;
  background-color: #aaa;
  animation: cursor-blink 1s infinite;
}

@keyframes cursor-blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
